import React from "react"
import { Link } from "gatsby"

import Layout from "../components/common/layout"
// import Image from "../components/image"
import SEO from "../components/common/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div>
      <div>
        <section id="section-1">section 1</section>
        <section id="section-2">section 2</section>
        <section id="section-3">section 3</section>
      </div>
    </div>
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      {/* <Image /> */}
    </div>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)

export default IndexPage
